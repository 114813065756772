import React from 'react';
import './App.css';
import Carousel from './components/Carousel';
import Home from './pages/Home';
import { Route, Routes } from 'react-router-dom';
import About from './pages/About';
import Founder from './pages/Founder';
import Chairman from './pages/Chairman';
import Vision from './pages/Vision';
import Board from './pages/Board';
const App = () => {
  

    return (
        <Routes>
            <Route path='/' element={<Home/>}/>
            <Route path='/about' element={<About/>}/>
            <Route path='/founder' element={<Founder/>}/>
            <Route path='/chairman' element={<Chairman/>}/>
            <Route path='/vision-mission' element={<Vision/>}/>
            <Route path='/board-of-directors' element={<Board/>}/>
        </Routes>
    );
};

export default App;
