import React, { useState,useEffect } from 'react'
import Slider from '../components/Slider'
import header2 from '../image/header2.jpg';
import header3 from '../image/header3.jpg';
import kaveripakkam from '../image/kaveripakkam.jpg';
import walajah from '../image/walajah.jpg';
import cheyyar from '../image/cheyyar.jpg';
import thimiri from '../image/thimiri.jpg';
import { managementarr } from '../components/managementarray';
import { Helmet } from "react-helmet";
import Footer from '../components/Footer';
import AOS from 'aos';
import panam from '../assets/images/panam.jpeg'
import 'aos/dist/aos.css';
const Board = () => {
    useEffect(() => {
        AOS.init()
    }, [])
    const images=[header2,panam,kaveripakkam,walajah,cheyyar,thimiri]
    const [story,setStory]=useState(managementarr[0].Stories)
    const [image,setImage]=useState(managementarr[0].Image)
    const [name,setName]=useState(managementarr[0].Name)
    const [designation,setDesignation]=useState(managementarr[0].Designation)
    const [studies,setStudies]=useState(managementarr[0].Studies)
    const aboutQuick=[
        {
            title:"Home",
            link:"/",
        },
        {
            title:"About",
            link:"/about",
        },
        {
            title:"Founders",
            link:"/founder",
        },
        {
            title:"Chairman",
            link:"/chairman",
        },
        {
            title:"Vision and Mission",
            link:"/vision-mission",
        }, 
    ]
  return (
    
    <div className='w-[1200px] md:w-full'>
        <Helmet>
        <meta charSet="utf-8" />
        <title>Board of Directors - Bharathidhasanar of schools</title>
        <link rel="canonical" href="https://bmhsschool.edu.in/board-of-directors" />
        <meta name="description" content="Board of Directors of bharathidhasanar group is well-experienced to lead. Get the real value of education in arakkonam(bgsarakkonam) cheyyar(bgscheyyar) walajapet(bgswalajapet) thimiri(bgsthimiri) panapakkam(bgspanapakkam)." />
        <meta property="og:https://bmhsschool.edu.in/board-of-directors" content="bharathidhasanar" />
        
      </Helmet>
         <Slider images={images} menuBackgroundImage={header2} quicklinks={aboutQuick} title={"BOARD OF DIRECTORS"} interval={3000} />
         <div className="md:flex mx-10 lg:mx-32 my-16">
            <div className='grid grid-cols-2 md:block' data-aos="fade-right" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600">
                {managementarr.map(m1=>(

                    <div className="flex my-5 shadow-lg  p-2 md:w-96 md:h-32 cursor-pointer hover:bg-blue-100 duration-500" onClick={()=>{
                        setName(m1.Name)
                        setStudies(m1.Studies)
                        setDesignation(m1.Designation)
                        setStory(m1.Stories)
                        setImage(m1.Image)

                    }}>
                <div className="my-auto">

                   <div className="">
                       <img className="w-32 md:w-20 md:h-20" src={m1.Image}/> 
                    </div> 
                </div>
                <div className="ml-5 my-auto">
                    <div className="text-4xl md:text-2xl">{m1.Name}</div>
                    <div className="text-2xl md:text-sm text-gray-500">{m1.Designation}</div>
                    <div className="text-2xl md:text-sm text-gray-500">{m1.Studies}</div>
                </div>
                </div> 
            ))}
            </div>
            <div data-aos="zoom-in" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600" className="shadow-lg my-5 lg:ml-32 w-full border-blue-950">
                <div className=" p-5">
                    <div>
                        <img className="rounded-2xl w-[1000px] md:w-[300px]" src={image}/>
                    </div>
                    <div className="text-blue-950">

                    <div className="font-bold mt-10 md:mt-2 text-7xl md:text-2xl">{name}</div>
                    <div className="font-semibold mt-2 md:mt-0 text-5xl md:text-sm">{designation}</div>
                    <div className="font-semibold text-5xl mt-2 md:mt-0 md:text-sm">{studies}</div>
                    </div>
                    <div className="text-justify text-4xl md:text-lg leading-normal mt-10">{story}</div>
                </div>
            </div>
            
        </div>
        <div className='mx-10 md:mx-0 my-5'><p className='text-center rounded-bl-[100px] leading-normal rounded-tr-[100px] mx-auto text-white px-20 md:text-xl py-16  bg-blue-950 text-5xl  w-fit mt-5'>SUCCESSFUL ORGANIZATIONS ARE BUILT ON STRONG BOARDS.</p></div>
        <div>
            <Footer/>
        </div>
    </div>
  )
}

export default Board