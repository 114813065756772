import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { RiMenuUnfoldFill } from "react-icons/ri";
import { IoMdClose } from "react-icons/io";
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from "react-icons/fa6";
import { IoMdArrowDropdown } from "react-icons/io";
import { LuMenu } from "react-icons/lu";
const Slider = ({ images,title,quicklinks, interval }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, interval);
    return () => clearInterval(intervalId);
  }, [images, interval]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="relative w-[1200px] h-[2100px] md:h-screen md:w-full  overflow-hidden font-serif tracking-wider">
      {images.map((image, index) => (
        <div
          key={index}
          className={`absolute top-0 left-0 w-full h-full transition-opacity duration-1000 ${index === currentImageIndex ? 'opacity-100' : 'opacity-0'
          } `}
          style={{
            backgroundImage: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${image})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            zIndex: index === currentImageIndex ? 1 : 0,
          }}
        >
         
          {/* <div className="fixed top-0 left-0 p-4">
            <LuMenu className=" text-5xl border-[1px] border-green-600 text-black bg-white cursor-pointer" onClick={toggleMenu} />
          </div> */}

            
            <div className="flex flex-col w-fit ml-[860px] mt-[1300px] md:mt-72   text-3xl md:text-sm md:ml-[80%] lg:ml-[85%] ">
            
          {quicklinks.map(m1=>(
        <Link to={m1.link}><div className=" mx-auto mt-5 md:mx-5 md:mt-2"><button  className="w-72  hover:-translate-y-1 duration-500 border-[#ffbf00] hover:bg-[#ffbf00]   border-[1px] bg-white py-5 md:px-2 md:w-40 md:p-2 ">{m1.title}  →</button></div></Link>
          ))}
          </div>
          <div className="absolute mt-36 md:mt-20 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-center">
            <div className="md:text-xl text-5xl mb-10 md:mb-3">Welcome to</div>
            <div className="md:text-5xl text-7xl font-bold yel">{title}</div>
            <div className="text-center mx-auto mt-16 md:mt-10 border-2 w-fit rounded-full p-2"><IoMdArrowDropdown className="text-6xl md:text-3xl"/></div>
          </div>
        </div>
      ))}
       
    </div>
  );
};

export default Slider;
