import React, { useEffect } from 'react'
import Slider from '../components/Slider'
import header2 from '../image/header2.jpg';
import header3 from '../image/header3.jpg';
import kaveripakkam from '../image/kaveripakkam.jpg';
import walajah from '../image/walajah.jpg';
import cheyyar from '../image/cheyyar.jpg';
import thimiri from '../image/thimiri.jpg';
import Footer from '../components/Footer';
import panam from '../assets/images/panam.jpeg'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from 'react-helmet';
const Vision = () => {
    useEffect(()=>{
        AOS.init()
    },[])
    const images=[header2,panam,kaveripakkam,walajah,cheyyar,thimiri]
    const aboutQuick=[
        {
            title:"Home",
            link:"/",
        },
        {
            title:"About",
            link:"/about",
        },
        {
            title:"Founders",
            link:"/founder",
        },
        {
            title:"Chairman",
            link:"/chairman",
        },
        {
            title:"Board of Directors",
            link:"/board-of-directors",
        }, 
    ]
  return (
    <div className='font-mono w-[1200px] md:w-full'>
        <Helmet>
         <meta charSet="utf-8" />
        <title>Vision - Bharathidhasanar group of schools</title>
        <link rel="canonical" href="https://bmhsschool.edu.in/vision-mission" />
        <meta name="description" content="The vision of bharathidhasanar group is solid to provide value. Get the real value of education in arakkonam(bgsarakkonam) cheyyar(bgscheyyar) walajapet(bgswalajapet) thimiri(bgsthimiri) panapakkam(bgspanapakkam)." />
        <meta property="og:title" content="bharathidhasanar schools" />
        </Helmet>
        <Slider images={images} menuBackgroundImage={header2} quicklinks={aboutQuick} title={"VISION AND MISSION"} interval={3000} />
        <div className='md:flex mx-10 md:mx-32 my-16' data-aos="fade-up"
     data-aos-anchor-placement="top-center" data-aos-duration="500">
            <div className='mr-16 md:w-[60%]' >
                <div className=''><h2 className='text-9xl md:text-6xl text-center font-bold text-yellow-500 drop-shadow-[2px_2px_black] my-32 md:my-0'>OUR VISION</h2></div>
                <div className='my-2'><p className='text-7xl leading-normal md:text-3xl text-center'>"Empowering Learners, Enriching Lives"</p></div>
                <div className='my-16 md:my-0'>
                    <p className='text-justify mt-8 text-5xl leading-normal md:text-lg text-gray-600'>We envision a school where every student is inspired to learn, grow, and achieve their fullest potential in an inclusive, supportive, and dynamic environment. Foster a culture of curiosity, critical thinking, and lifelong learning through a rigorous and engaging curriculum tailored to the diverse needs of our students.Promote the intellectual, emotional, social, and physical development of each student by providing a balanced education that includes academics, arts, sports, and life skills.</p>
                </div>
            </div>
            <div className='md:w-[40%] my-auto '>
                <img className='rounded-bl-[100px] rounded-tr-[100px]' src={header2}/>
            </div>
        </div>
        <div className='md:flex md:mx-32 mx-10 my-32' data-aos="fade-down"
     data-aos-anchor-placement="top-center" data-aos-duration="500">
            <div className='md:w-[40%] my-auto hidden md:block'>
                <img className='rounded-bl-[100px] rounded-tr-[100px]' src={header2}/>
            </div>
            <div className='md:ml-16 md:w-[60%]'>
                <div className=''><h2 className='text-9xl md:text-6xl text-center font-bold my-32 md:my-0 text-yellow-500 drop-shadow-[2px_2px_black]'>OUR MISSION</h2></div>
                <div className='my-2'><p className='text-7xl leading-normal md:text-3xl text-center'>"Empowering Learners, Enriching Lives"</p></div>
                <div>
                    <p className='text-justify text-5xl my-16 md:my-0 leading-normal md:text-lg mt-8 text-gray-600'>We envision a school where every student is inspired to learn, grow, and achieve their fullest potential in an inclusive, supportive, and dynamic environment. Foster a culture of curiosity, critical thinking, and lifelong learning through a rigorous and engaging curriculum tailored to the diverse needs of our students.Promote the intellectual, emotional, social, and physical development of each student by providing a balanced education that includes academics, arts, sports, and life skills.</p>
                </div>
            </div>
            <div className='md:w-[40%] my-auto md:hidden '>
                <img className='rounded-bl-[100px] rounded-tr-[100px]' src={header2}/>
            </div>
        </div>
        <div className='mb-32' data-aos="fade-right"
     data-aos-anchor-placement="top-center" data-aos-duration="500">
            <div className='text-center '><p className='text-9xl md:text-6xl text-center font-bold text-yellow-500 drop-shadow-[2px_2px_black] mb-10'>OUR MOTTO</p></div>
            <div className='mx-10 md:mx-0'><p className='text-center rounded-bl-[100px] leading-normal rounded-tr-[100px] mx-auto text-white px-20 md:text-xl py-16  bg-blue-950 text-5xl  w-fit'>PROGRESS AND PROSPERITY THROUGH EDUCATION</p></div>
        </div>
        <div>
            <Footer/>
        </div>
    </div>
  )
}

export default Vision