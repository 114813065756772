import React from 'react'
import Slider from '../components/Slider'
import header2 from '../image/header2.jpg';
import header3 from '../image/header3.jpg';
import kaveripakkam from '../image/kaveripakkam.jpg';
import walajah from '../image/walajah.jpg';
import cheyyar from '../image/cheyyar.jpg';
import thimiri from '../image/thimiri.jpg';
import Footer from '../components/Footer';
import panam from '../assets/images/panam.jpeg'
import anon from '../assets/images/profile.png'
import chairman from '../assets/images/chairman.png';
import { Helmet } from 'react-helmet';
const Chairman = () => {
    const images=[header2,panam,kaveripakkam,walajah,cheyyar,thimiri]
    const aboutQuick=[
        {
            title:"Home",
            link:"/",
        },
        {
            title:"About",
            link:"/about",
        },
        {
            title:"Founders",
            link:"/founder",
        },
        {
            title:"Vision and Mission",
            link:"/vision-mission",
        },
        {
            title:"Board of Directors",
            link:"/board-of-directors",
        }, 
    ]
  return (
    <div className='w-[1200px] md:w-full'>
        <Helmet>
        <meta charSet="utf-8" />
        <title>Chariman - Bharathidhasanar group of schools</title>
        <link rel="canonical" href="https://bmhsschool.edu.in/vision-mission" />
        <meta name="description" content="Chairman carries the visiono of the founder to eternity. Get the real value of education in arakkonam(bgsarakkonam) cheyyar(bgscheyyar) walajapet(bgswalajapet) thimiri(bgsthimiri) panapakkam(bgspanapakkam)." />
        <meta property="og:title" content="bharathidhasanar schools" />
        </Helmet>
         <Slider images={images} menuBackgroundImage={header2} quicklinks={aboutQuick} title={"CHAIRMAN"} interval={3000} />
         <div className="my-32 md:flex mx-32 ">
                    <div className="md:w-[70%] mt-16">
                        <h1 className="text-9xl md:text-5xl font-serif text-primary mb-5 yel">CHAIRMAN</h1>
                        <p className="md:text-lg my-16 md:my-0 text-5xl leading-normal font-[content] text-justify ">Sri. S. Sundar has been the Chairman of Bharathidasanar Group of Schools since its early days, building on the legacy of Late Sri. P. Sekar and Late Smt. Lakshmi Amma. What began as a single school in Arakkonam in 1980 has flourished under his visionary leadership into nearly 10 successful branches across Cheyyar, Thimiri, Walajapet, Kaveripakkam, and Panapakkam.

His dedication to educational excellence has resulted in the education of over 6000 students, including more than 400 doctors and 600 engineers among our distinguished alumni. Sri. S. Sundar’s commitment to holistic development continues to drive the institution’s growth and success.

Under his guidance, Bharathidasanar Group of Schools remains at the forefront of educational innovation, providing a nurturing environment where students can explore their potential and achieve their dreams.</p>
<div className='mx-10 md:mx-0'><p className='text-center rounded-bl-[100px] leading-normal rounded-tr-[100px] mx-auto text-white px-20 md:text-xl py-16  bg-blue-950 text-5xl  w-fit mt-5'>LEADERS LEAD BY EXAMPLE.</p></div>
                    </div>
                    <div className="md:w-[30%]  md:ml-16">
                        <img className="md:h-96" src={chairman} />
                    </div>
                </div>
                <div>
                    <Footer/>
                </div>

    </div>
  )
}

export default Chairman