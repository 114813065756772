import React, { useEffect } from 'react'
import Slider from '../components/Slider'
import header2 from '../image/header2.jpg';
import header3 from '../image/header3.jpg';
import kaveripakkam from '../image/kaveripakkam.jpg';
import walajah from '../image/walajah.jpg';
import cheyyar from '../image/cheyyar.jpg';
import thimiri from '../image/thimiri.jpg';
import bgs from '../image/bgs.jpeg'
import CountUp from 'react-countup';
import Parallax from '../components/Parallax';
import Footer from '../components/Footer';
import panam from '../assets/images/panam.jpeg'
import { Helmet } from "react-helmet";
import AOS from 'aos';
import 'aos/dist/aos.css';
const About = () => {
  useEffect(() => {
    AOS.init()
  }, [])
  const images = [header2, panam, kaveripakkam, walajah, cheyyar, thimiri]
  const aboutQuick = [
    {
      title: "Home",
      link: "/",
    },
    {
      title: "Founder",
      link: "/founder",
    },
    {
      title: "Chairman",
      link: "/chairman",
    },
    {
      title: "Vision and Mission",
      link: "/vision-mission",
    },
    {
      title: "Board of Directors",
      link: "/board-of-directors",
    },


  ]
  return (
    <div className='font-mono w-[1200px] md:w-full'>
       <Helmet>
        <meta charSet="utf-8" />
        <title>About us - Bharathidhasanar group of schools</title>
        <link rel="canonical" href="https://bmhsschool.edu.in/board-of-directors" />
        <meta name="description" content="Bharathidhasanar is a group of schools have multiples branches. Get the real value of education in arakkonam(bgsarakkonam) cheyyar(bgscheyyar) walajapet(bgswalajapet) thimiri(bgsthimiri) panapakkam(bgspanapakkam)." />
        <meta property="og:https://bmhsschool.edu.in/board-of-directors" content="pets.abc" />
        <meta property="og:site_name" content="Pets - Products" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
      </Helmet>
      <Slider images={images} menuBackgroundImage={header2} quicklinks={aboutQuick} title={"ABOUT US"} interval={3000} />
      <div className='md:flex py-16 px-10 md:px-32'  >
        <div className='md:w-[60%] mr-16 my-auto' data-aos="zoom-in"
          data-aos-anchor-placement="top-center" data-aos-duration="1000">
          <h2 className='text-8xl md:text-6xl mb-10 md:leading-[60px] font-bold text-yellow-500 drop-shadow-[2px_2px_black]'>BHARATHIDHASANAR GROUP OF SCHOOLS</h2>
          <p className='text-justify text-5xl md:text-[17px] leading-normal'>Since 1980, BGS Schools have been dedicated to providing knowledge and discipline across various branches including Arakkonam, Cheyyar, Thimiri, Walajapet, Kaveripakkam, and Panapakkam. Our holistic approach to education emphasizes academic excellence and character development. We create a nurturing environment where students can explore their potential and cultivate a love for lifelong learning. Our experienced educators are passionate about guiding students through their educational journey. With a comprehensive curriculum and state-of-the-art facilities, we ensure a well-rounded education. </p>
        </div>
        <div className='md:w-[40%] my-32 md:my-auto' data-aos="zoom-out"
          data-aos-anchor-placement="top-center" data-aos-duration="1000">
          <img className='rounded-bl-[100px] rounded-tr-[100px] ' src={header2} />
        </div>
      </div>
      <div id='success' >
        <div className='flex py-2 mb-16 text-white  rounded-lg bg-yellow-500  w-fit mx-auto '>
          <div className='border-r-[1px]  px-20 py-7 border-white'>
            <CountUp start={0} end={5000} delay={0} enableScrollSpy={true}>
              {({ countUpRef }) => (
                <div className='flex '>
                  <span className='text-7xl md:text-3xl font-semibold mr-2' ref={countUpRef} /><div className='my-auto text-5xl md:text-sm'>+</div>
                </div>
              )}
            </CountUp>
            <div>
              <p className='text-5xl md:text-sm text-gray-100'>Students</p>
            </div>

          </div>
          <div className='border-r-[1px]  px-20 py-7 border-white'>
            <CountUp start={0} end={600} delay={0} enableScrollSpy={true}>
              {({ countUpRef }) => (
                <div className='flex '>
                  <span className='text-7xl md:text-3xl font-semibold mr-2' ref={countUpRef} /><div className='my-auto text-5xl md:text-sm'>+</div>
                </div>
              )}
            </CountUp>
            <div>
              <p className='text-5xl md:text-sm text-gray-100'>Engineers</p>
            </div>
          </div>
          <div className='px-20 py-7'>
            <CountUp start={0} end={400} delay={0} enableScrollSpy={true}>
              {({ countUpRef }) => (
                <div className='flex'>
                  <span className='text-7xl md:text-3xl font-semibold mr-2' ref={countUpRef} /><div className='my-auto text-5xl md:text-sm'>+</div>
                </div>
              )}

            </CountUp>
            <div>
              <p className='text-5xl md:text-sm text-gray-100'>Doctors</p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Parallax image={bgs} gradient="rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)">
          <div className='gradient'></div>
          <div>
            <h2 className=' mx-32 text-8xl md:text-5xl leading-normal '>
              “ Education is what remains after one has forgotten what one has learned in school. ”
            </h2>
            <p className='ml-[50%] text-3xl mt-10 yel'>-  Albert Einstein</p>
          </div>
        </Parallax>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  )
}

export default About