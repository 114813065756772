import empty from '../assets/images/profile.png'
import padma from '../assets/images/padma.jpg'
import hari from  '../assets/images/hari.JPG'
import dsm from '../assets/images/dsm.jpg'
import vasanth from '../assets/images/vasanth.jpg'
import chairman from '../assets/images/chairman.png';
export const managementarr=[
    {
        Name:"Mr. S. Sundar",
        Designation:"Chairman",
        Studies:"B. Com., M.A.,",
        Image:chairman,
        Stories:"Under the visionary leadership of Mr. S. Sundar, Chairman of BGS Group of Schools, we are committed to fostering academic excellence and holistic development in our students. Mr. Sundar, who holds a B. Com. and an M.A., brings a wealth of knowledge and over 40 years of experience to our educational institutions. His dedication to quality education and innovative teaching methods ensures that our schools provide a nurturing and stimulating environment for young minds. At BGS Group of Schools, we believe in cultivating a culture of curiosity, creativity, and critical thinking. Our comprehensive curriculum is designed to equip students with the skills and values necessary to thrive in an ever-changing world. Through a blend of academic rigor and extracurricular activities, we aim to develop well-rounded individuals who are ready to contribute positively to society. Join us as we continue our tradition of excellence and commitment to shaping the leaders of tomorrow.",
    },
    {
        Name:"Mrs. S. Padma",
        Designation:"Director of Finance",
        Studies:"Studies",
        Image:padma,
        Stories:"Mrs. S. Padma, the esteemed Director of Finance Studies at BGS Group of Schools, brings unparalleled expertise in financial education with over two decades of experience in the field. With a robust academic background, she has been instrumental in developing a comprehensive and practical finance curriculum. Her commitment to excellence and innovative teaching methodologies ensures that students receive a deep understanding of financial principles, covering fundamentals of financial management, accounting, real-world applications, and emerging trends. Mrs. Padma's dedication to fostering financial literacy and critical thinking skills equips our students with the essential tools to excel in the complexities of the financial world. Her passion for empowering students with financial acumen and focus on ethical financial practices are key components of our mission to provide a well-rounded education that prepares them for future success.",
    },
    {
        Name:"Mr. J. Deivasigamani",
        Designation:"Director of Internal Affairs",
        Studies:"M.Com., B.Ed.,",
        Image:dsm,
        Stories:"Mr. J. Deivasigamani, the Director of Internal Affairs at BGS Group of Schools, brings a wealth of experience and expertise in mathematics education, having devoted 40 years to teaching the subject. Holding an M.Com. and a B.Ed., he combines his extensive knowledge with a passion for fostering academic excellence. Throughout his illustrious career, he has successfully guided and mentored over 5,000 students, earning a reputation as a dedicated and inspiring educator. His innovative teaching methodologies have consistently helped students achieve outstanding academic results and develop a strong foundation in mathematics. As Director of Internal Affairs, Mr. Deivasigamani ensures that the school's internal operations run smoothly, maintaining high standards of discipline, academic integrity, and administrative excellence. His unwavering commitment to both administrative duties and mathematics education is a cornerstone of our mission to provide high-quality education and holistic development for our students, continuing to inspire both students and faculty.",
    },
    {
        Name:"Mr.K.Hari",
        Designation:"Director of",
        Studies:"Studies",
        Image:hari,
        Stories:"Mr. Hari, the Director of Studies at BGS Group of Schools, brings 25 years of invaluable experience to his role, with a specialized focus on Biology. His extensive expertise in the field has been instrumental in developing and refining our biology curriculum, ensuring it is both rigorous and aligned with the latest scientific advancements. Mr. Hari’s passion for biology and education is reflected in his innovative teaching methods and his commitment to fostering a deep understanding of the subject. He excels in creating a stimulating academic environment that encourages curiosity and critical thinking. As a leader, Mr. Hari not only supports the academic growth of our students but also mentors faculty to enhance their teaching practices. His strategic vision and dedication to academic excellence are central to the success of our educational programs, driving continuous improvement and ensuring that our students are well-prepared for future scientific challenges and opportunities.",
    },
    {
        Name:"Mr. T. Vasantha Kumar",
        Designation:"Director of Physical Education",
        Studies:"B.Ed. , M.Ed. (Physical Education)",
        Image:vasanth,
        Stories:"Mr. T. Vasantha Kumar, the Director of Physical Education at BGS Group of Schools, brings a decade of dedicated experience and expertise in the field of physical education. With a B.Ed. and an M.Ed. in Physical Education, he possesses a deep understanding of both educational theory and practical application. His extensive skills in various sports and physical activities have enabled him to design and implement a dynamic and comprehensive physical education program that emphasizes fitness, skill development, and teamwork. Mr. Vasantha Kumar is known for his innovative approaches to coaching and his ability to inspire students to excel in sports and maintain an active lifestyle. His leadership ensures that the physical education curriculum is engaging, inclusive, and aligned with current best practices, fostering not only physical fitness but also a sense of discipline and collaboration. His commitment to nurturing student potential and promoting overall well-being plays a crucial role in enhancing the holistic development of our students.",
    },
    // {
    //     Name:"Name",
    //     Designation:"Principal",
    //     Studies:"Studies",
    //     Image:empty,
    //     Stories:"Studies",
    // },
]