import React, { useEffect, useState } from 'react'
import Carousel from '../components/Carousel'
import '../components/Carousel.css'
import { Link } from 'react-router-dom'
import Footer from '../components/Footer'
import { Helmet } from 'react-helmet'
const Home = () => {
  return (
    <div className='w-[1200px] md:w-full'>
      <Helmet>
      <meta charSet="utf-8" />
        <title>Home - Bharathidhasanar group of schools</title>
        <link rel="canonical" href="https://bmhsschool.edu.in/vision-mission" />
        <meta name="description" content="Chairman carries the visiono of the founder to eternity. Get the real value of education in arakkonam(bgsarakkonam) cheyyar(bgscheyyar) walajapet(bgswalajapet) thimiri(bgsthimiri) panapakkam(bgspanapakkam)." />
        <meta property="og:title" content="bharathidhasanar schools" />
        </Helmet>
      <header>
    <nav className='text-3xl  md:text-xl mt-36 md:mt-0 mx-auto '>
      <Link to='/about'>About Us</Link>
      <Link to='/founder'>Founders</Link>
      <Link to='chairman'>Chairman</Link>
      <Link to='vision-mission'>Vision and Mission</Link>
      <Link to='board-of-directors'>Board of Directors</Link>
      {/* <img className="logo" src="image/logo.png" alt="Logo" /> */}
    </nav>
    </header>
    <div>

        <Carousel />
    </div>
        <div>

        <Footer/>
        </div>
        </div>
  )
}

export default Home