import React, { useEffect } from 'react'
import Slider from '../components/Slider'
import header2 from '../image/header2.jpg';
import header3 from '../image/header3.jpg';
import kaveripakkam from '../image/kaveripakkam.jpg';
import walajah from '../image/walajah.jpg';
import cheyyar from '../image/cheyyar.jpg';
import thimiri from '../image/thimiri.jpg';
import Footer from '../components/Footer';
import panam from '../assets/images/panam.jpeg'
import founder from '../assets/images/founder.jpg'

import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from 'react-helmet';
const Founder = () => {
    useEffect(() => {
        AOS.init()
    }, [])
    const images = [header2, panam, kaveripakkam, walajah, cheyyar, thimiri]
    const aboutQuick = [
        {
            title: "Home",
            link: "/",
        },
        {
            title: "About",
            link: "/about",
        },
        {
            title: "Chairman",
            link: "/chairman",
        },
        {
            title: "Vision and Mission",
            link: "/vision-mission",
        },
        {
            title: "Board of Directors",
            link: "/board-of-directors",
        },
    ]
    return (
        <div className='w-[1200px] md:w-full'>
            <Helmet>
        <meta charSet="utf-8" />
        <title>Founder - Bharathidhasanar</title>
        <link rel="canonical" href="https://bmhsschool.edu.in/board-of-directors" />
        <meta name="description" content="The founder  of bharathidhasanar group has a clear vision. Get the real value of education in arakkonam(bgsarakkonam) cheyyar(bgscheyyar) walajapet(bgswalajapet) thimiri(bgsthimiri) panapakkam(bgspanapakkam)." />
        <meta property="og:title" content="bharathidhasanar schools" />
        
      </Helmet>
            <div>
            </div>
            <Slider images={images} menuBackgroundImage={header2} quicklinks={aboutQuick} title={"FOUNDERS"} interval={3000} />
            <div className="my-32 md:flex mx-32 items-center justify-center">
                <div className="md:w-[70%] mt-16">
                    <h1 className="text-9xl md:text-5xl font-serif yel mb-5">FOUNDERS</h1>
                    <p className="text-5xl leading-normal my-16 md:text-lg font-[content] text-justify ">Bharathidasanar Group of Schools stands as a testament to the visionary leadership of Late Sri. P. Sekar and his wife Late Smt. Lakshmi Amma, the esteemed founders. Their unwavering commitment to education laid the foundation for this institution, fostering an environment of learning and growth. Since 1980, BGS Schools have been dedicated to providing knowledge and discipline across various branches, including Arakkonam, Cheyyar, Thimiri, Walajapet, Kaveripakkam, and Panapakkam.

What began as a small school in Arakkonam has now grown into a respected educational network, known for its holistic approach to education. This approach emphasizes academic excellence and character development, ensuring a well-rounded education for every student. We create a nurturing environment where students can explore their potential, develop critical thinking skills, and cultivate a love for lifelong learning. Our experienced educators are passionate about guiding students through their educational journey, supported by a comprehensive curriculum and state-of-the-art facilities.    
                        </p>
                        <div className='mx-10 md:mx-0'><p className='text-center rounded-bl-[100px] leading-normal rounded-tr-[100px] mx-auto text-white px-20 md:text-xl py-16  bg-blue-950 text-5xl  w-fit'>EMPOWERING FUTURES THROUGH KNOWLEDGE AND GROWTH</p></div>
                </div>
                <div className="md:w-[30%]  md:ml-16">
                    <img className="md:h-[330px] top-2 rounded-lg" src={founder} />
                </div>
            </div>
            <div>
                <Footer />
            </div>
        </div>
    )
}

export default Founder